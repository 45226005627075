import { types as api } from '@mesa-labs/mesa-api';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  DownloadButton,
  Input,
  Pagination, PaginationDropdown, Search, Table, Typography,
} from '@mesa-labs/mesa-ui';
import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';

import { DefaultPaginationDropdownLimits } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import Dropdown, { DropdownOption } from '@mesa-labs/mesa-ui/dist/components/Dropdown';
import {
  asExportFilter, exportAllExternalVendorsAsFacilitator, useGetAllExternalVendorsAsFacilitatorQuery, useGetExternalVendorFiltersAsFacilitatorQuery,
} from '../../redux/api/facilitators';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateLimit, updatePage, updateSearchTerm, updateTotalPages, updateSortField, updateSortDirection, updateAddress, updateServiceType, updateLocale,
} from '../../redux/slices/vendors';
import {
  FilterCell, FilterRow, FilterSection, FilterTitle,
} from '../common/Filters';

const VendorsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
`;

const ClearFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 21px;
`;

const SearchContainer = styled.div`
  width: 600px;
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 12px 0 12px;
`;

export const VendorColumns: ColumnProp[] = [
  {
    key: 'externalVendorName',
    sortField: 'externalVendorName',
    label: 'Name',
    width: '30%',
  },
  {
    key: 'externalVendorId',
    label: 'Supplier Id',
    width: '20%',
  },
  {
    key: 'address',
    label: 'Address',
    width: '15%',
  },
  {
    key: 'serviceType',
    label: 'Service Type',
    width: '20%',
  },
  {
    key: 'activatedAt',
    sortField: 'activatedAt',
    label: 'Enablement Date',
    width: '15%',
    type: 'date',
    tooltip: 'An empty value indicates that the Supplier has signed up for Mesa but has not yet been enabled within JLL',
  },
];

const partnerId = 1; // TODO

const LocaleDropdownOptions: DropdownOption<string>[] = [
  {
    label: 'No Selection',
    value: '',
  },
  ...(Object.keys(api.VendorLocale)).map((v: string) => ({
    label: v,
    value: v,
  })),
];

function VendorsPage(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const searchTerm = useSelector((state) => state.vendors.searchTerm);
  const address = useSelector((state) => state.vendors.address);
  const serviceType = useSelector((state) => state.vendors.serviceType);
  const locale = useSelector((state) => state.vendors.locale);
  const page = useSelector((state) => state.vendors.page) || 1;
  const totalPages = useSelector((state) => state.vendors.totalPages);
  const limit = useSelector((state) => state.vendors.limit) || DefaultPaginationDropdownLimits[0];
  const sortField = useSelector((state) => state.vendors.sortField);
  const sortDirection = useSelector((state) => state.vendors.sortDirection);

  const [searchInput, setSearchInput] = useState(searchTerm || '');
  const [addressInput, setAddressInput] = useState(address || '');

  const filter = useMemo(() => ({
    search: searchTerm,
    address: address || undefined,
    serviceType: serviceType || undefined,
    locale: (locale || undefined) as api.VendorLocale | undefined,
    sortField,
    sortDirection,
    limit: (typeof limit === 'number' ? limit : limit.value),
    page,
  }), [searchTerm, address, serviceType, locale, sortField, sortDirection, limit, page]);

  const {
    data: {
      data: vendors = [],
      total: totalVendors,
    } = {},
    isFetching,
  } = useGetAllExternalVendorsAsFacilitatorQuery({ accessToken: accessToken!, partnerId: partnerId!, ...filter }, { skip: !partnerId });

  const {
    data: externalVendorFilters = { serviceTypes: [] },
  } = useGetExternalVendorFiltersAsFacilitatorQuery({ accessToken: accessToken!, partnerId: partnerId! }, { skip: !partnerId });

  const serviceTypeDropdownOptions: DropdownOption<string>[] = useMemo(() => [
    {
      label: 'No Selection',
      value: '',
    },
    ...(externalVendorFilters?.serviceTypes || []).map((v: string) => ({
      label: v,
      value: v,
    })),
  ], [externalVendorFilters]);

  const rows = useMemo(() => vendors.map((vendor) => ({
    id: vendor.externalVendorId,
    externalVendorName: vendor.externalVendorName,
    externalVendorId: vendor.externalVendorId,
    address: vendor.registeredAddressRegion,
    serviceType: vendor.serviceType,
    activatedAt: vendor.activatedAt,
  })), [vendors]);

  useEffect(() => {
    if (totalVendors !== undefined) {
      dispatch(updateTotalPages(Math.ceil(totalVendors / (typeof limit === 'number' ? limit : limit.value))));
    }
  }, [limit, totalVendors]);

  const onSubmitSearch = (value: string) => {
    const trimmed = value.trim();
    if (trimmed) {
      dispatch(updateSearchTerm(trimmed));
    } else {
      setSearchInput('');
      dispatch(updateSearchTerm(''));
    }
    dispatch(updatePage(1));
  };

  const onFinalizeAddress = (value: string) => {
    const trimmed = value.trim();
    if (trimmed) {
      dispatch(updateAddress(trimmed));
    } else {
      setAddressInput('');
      dispatch(updateAddress(''));
    }
    dispatch(updatePage(1));
  };

  const onClearFilters = () => {
    setAddressInput('');
    dispatch(updateAddress(''));
    dispatch(updateServiceType(''));
    dispatch(updateLocale(''));
    dispatch(updatePage(1));
  };

  const appliedFilters = useMemo(() => [address, serviceType].reduce((acc: number, curr) => acc + (curr ? 1 : 0), 0), [address, serviceType]);

  return (
    <VendorsPageContainer>
      <FilterSection
        filterControl={(
          <FilterControls>
            <SearchContainer>
              <Search
                placeholder="Search by Supplier Name or Supplier Id. Press Enter to search, Escape to clear"
                onChange={(value) => setSearchInput(value)}
                onSubmit={onSubmitSearch}
                value={searchInput}
                expanded
              />
            </SearchContainer>
            <DownloadButton
              text="Download CSV"
              data={() => exportAllExternalVendorsAsFacilitator(partnerId, asExportFilter(filter), accessToken!)}
              fileName="suppliers.csv"
            />
          </FilterControls>
        )}
        appliedFilters={appliedFilters}
      >
        <FilterRow>
          <FilterCell>
            <FilterTitle>Address</FilterTitle>
            <Input
              type="text"
              placeholder="Filter by City or State"
              onChange={(evt) => setAddressInput(evt.target.value)}
              onFinalize={onFinalizeAddress}
              value={addressInput}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Service Type</FilterTitle>
            <Dropdown
              options={serviceTypeDropdownOptions}
              selectedOption={serviceType}
              onChange={(st) => dispatch(updateServiceType(st))}
            />
          </FilterCell>
          <FilterCell>
            <FilterTitle>Locale</FilterTitle>
            <Dropdown
              options={LocaleDropdownOptions}
              selectedOption={locale}
              onChange={(l) => dispatch(updateLocale(l))}
              width="100%"
            />
          </FilterCell>
          <FilterCell>
            <ClearFiltersContainer>
              <Button
                text="Clear Filters"
                onClick={onClearFilters}
                iconSrc="/assets/delete.svg"
              />
            </ClearFiltersContainer>
          </FilterCell>
        </FilterRow>
      </FilterSection>

      <Table
        isLoading={isFetching}
        columns={VendorColumns}
        rows={rows}
        currentSortColumn={sortField}
        currentSortDirection={sortDirection}
        setSortField={(field: string) => dispatch(updateSortField(field))}
        setSortDirection={(direction: api.SortDirection) => dispatch(updateSortDirection(direction))}
        onRowClick={(row: Record<string, unknown>) => navigate(`/suppliers/${row.id}`)}
        paginationComponent={(
          <PaginationContainer>
            <PaginationDropdown
              selectedLimit={(typeof limit === 'number' ? DefaultPaginationDropdownLimits.find((l) => l.value === limit) || DefaultPaginationDropdownLimits[0] : limit)}
              onChange={(item) => {
                dispatch(updateLimit(item));
                dispatch(updatePage(1));
              }}
            />
            <Typography.BodySmallBold>
              Total of
              {' '}
              {totalVendors !== undefined ? totalVendors : '...'}
              {' '}
              Supplier(s)
            </Typography.BodySmallBold>
            <Pagination
              currentPage={page}
              totalPages={totalPages || 0}
              onPrev={() => dispatch(updatePage(page - 1))}
              onNext={() => dispatch(updatePage(page + 1))}
            />
          </PaginationContainer>
        )}
      />
    </VendorsPageContainer>
  );
}

export default VendorsPage;
