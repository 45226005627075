import { Typography } from '@mesa-labs/mesa-ui';
import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ThunkActionDispatch } from 'redux-thunk';
import styled from 'styled-components';

import { useDispatch, useSelector } from '../../redux/hooks';
import { signOut } from '../../redux/slices/auth';

const NavigationContainer = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.White};
  color: #2E2C34;
  display: flex;
  filter: drop-shadow(0px 3px 23px rgba(224, 225, 229, 0.5));
  flex-direction: column;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 32px;
  width: 100%;
`;

const LogoContainer = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 52px;
`;

const Logo = styled.img`
  padding: 20px 0;
  transform: scale(0.90);
`;

const Left = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const BackLink = styled.div`
  align-items: center;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.Cobalt};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-evenly;
  line-height: 130%;
  margin-bottom: 8px;
  max-width: 80px;
  overflow: hidden;
  padding: 8px;
  text-overflow: ellipsis;
  transition: background 100ms ease-in-out;
  white-space: nowrap;

  &:hover {
    background: rgba(129, 156, 251, 0.1);
  }
`;

export const BackLinkIcon = styled.img`
  margin-right: 8px;
`;

const RightIcons = styled.div`
  align-items: center;
  display: flex;
  font-size: 15px;
  justify-content: flex-end;
  gap: 8px;

  & > img {
    margin-right: 24px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;

const SignInLink = styled(Link)`
  border: 1px solid #EEE;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: #FAFAFA;
  }
`;

const NavContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
`;

const NavLink = styled(Link) <{ selected: boolean }>`
  background: ${(props) => (props.selected && 'rgba(129, 156, 251, 0.1)') || 'transparent'};
  border-radius: 4px; 
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 14px;
  position: relative;
`;

function Navigation(): React.ReactElement {
  const location = useLocation();
  const dispatch = useDispatch() as ThunkActionDispatch<any>;
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const email = useSelector((state) => state.auth.email);

  const { oktaAuth } = useOktaAuth();

  const handleSignOut = async () => {
    dispatch(signOut(oktaAuth));
  };

  return (
    <NavigationContainer>
      <HeaderContainer>
        <Left>
          <LogoContainer to="/">
            <Logo src="/assets/mesa.svg" />
          </LogoContainer>
        </Left>

        {isAuthorized && (
          <NavContainer>
            <NavLink to="/suppliers" selected={location.pathname.startsWith('/suppliers')}>Suppliers</NavLink>
            <NavLink to="/clients" selected={location.pathname.startsWith('/clients')}>Clients</NavLink>
            <NavLink to="/divisions" selected={location.pathname.startsWith('/divisions')}>Divisions</NavLink>
          </NavContainer>
        )}

        <RightIcons>
          {isAuthorized && (
            <>
              <Typography.LabelSmallBold>{email}</Typography.LabelSmallBold>
              <Icon
                src="/assets/logout-icon.svg"
                onClick={handleSignOut}
              />
            </>
          )}
          {!isAuthorized && (
            <SignInLink to="/sign-in">Sign in</SignInLink>
          )}
        </RightIcons>
      </HeaderContainer>
    </NavigationContainer>
  );
}

export default Navigation;
