import React, {
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import { LegalStyles, theme, Typography } from '@mesa-labs/mesa-ui';
import { Security as OktaSecurity } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import Routes from '../routes';
import Navigation, { BackLink, BackLinkIcon } from './common/Navigation';
import oktaAuth from '../okta';
import Footer from './common/Footer';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  #modal-root {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  #notification-root {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 3;
  }

  body {
    background: #FDFDFD;
    font-family: ${(props) => props.theme.fonts.primary};
    padding: 0px;
    margin: 0px;

    input:focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0px;
    padding: 0px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    box-sizing: border-box;
  }
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    z-index: 9999 !important;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: table;
    margin: -0.5em;
    z-index: 9999 !important;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
    z-index: 9999 !important;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: table;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: table;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
`;

const PageBackground = styled.img`
  bottom: 0px;
  left: 0px;
  height: 100%;
  position: fixed;
  width: 100%;
`;

const Layout = styled.div`
  font-size: 16px;
  height: 100%;
`;

const ContentContainer = styled.div`
  margin-top: 104px;
  padding: 0px 32px 32px 32px;
  position: relative;
`;

const Header = styled(Typography.H2)`
  color: ${(props) => props.theme.colors.Midnight};
  padding: 12px 0px 24px 0px;
`;

const routeToHeader: [RegExp, string][] = [
  [/^\/suppliers$/, 'Supplier Search'],
  [/^\/suppliers\/.+$/, 'Supplier Details'],
  [/^\/clients$/, 'Client Search'],
  [/^\/clients\/.+$/, 'Client Details'],
  [/^\/divisions$/, 'Divisions'],
  [/^\/divisions\/.+$/, 'Division Details'],
];

const routeToBackButton: RegExp[] = [
  /suppliers\/.*/,
  /clients\/.*/,
  /divisions\/.*/,
];

function Content({ children }: { children: ReactNode }): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const pageHeader = useMemo(() => {
    const match = routeToHeader.find((route) => route[0].test(location.pathname));

    if (match) {
      return match[1];
    }

    return null;
  }, [location.pathname]);

  const showBackButton = useMemo(() => routeToBackButton.find((route) => route.test(location.pathname)), [location.pathname]);

  const customAuthHandler = (_oktaAuth: OktaAuth) => {
    navigate('/sign-in');
  };

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <OktaSecurity
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <ContentContainer>
        {showBackButton && (
          <BackLink onClick={() => navigate(-1)}>
            <BackLinkIcon src="/assets/back-icon.svg" />
            Back
          </BackLink>
        )}
        {pageHeader && <Header>{pageHeader}</Header>}
        {children}
        <Footer />
      </ContentContainer>
    </OktaSecurity>
  );
}

function App(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <LegalStyles />

      <Layout id="operations-ui">
        <PageBackground src="/assets/page-background.svg" />
        <Router>
          <Content>
            <Navigation />
            <Routes />
          </Content>
        </Router>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
