import React, { ReactElement, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingScreen } from '@mesa-labs/mesa-ui';
import { Api } from '@mesa-labs/mesa-api';
import { ThunkActionDispatch } from 'redux-thunk';

import { useDispatch, useSelector } from '../redux/hooks';
import { signOut } from '../redux/slices/auth';

export default function OktaSecureRoute(): ReactElement {
  const dispatch = useDispatch() as ThunkActionDispatch<any>;
  const { authState, oktaAuth } = useOktaAuth();
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const accessTokenExpiresAt = useSelector((state) => state.auth.accessTokenExpiresAt);

  const isAccessTokenValid = useMemo(() => !!accessToken && !!accessTokenExpiresAt && !Api.hasTokenExpired({
    token: accessToken,
    expiresAt: accessTokenExpiresAt,
  }), [accessToken]);

  if (!authState) {
    return (<LoadingScreen />);
  } if (!authState.isAuthenticated || !isAuthorized || !isAccessTokenValid) {
    dispatch(signOut(oktaAuth));
    return (<Navigate to="/sign-in" />);
  }

  return (<Outlet />);
}
