import { types as api } from '@mesa-labs/mesa-api';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  LoadingScreen,
  Pagination, PaginationDropdown, Search, Table,
} from '@mesa-labs/mesa-ui';
import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';

import { DefaultPaginationDropdownLimits } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { useGetAllExternalClientsAsFacilitatorQuery } from '../../redux/api/facilitators';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateLimit, updatePage, updateSearchTerm, updateTotalPages, updateSortField, updateSortDirection,
} from '../../redux/slices/clients';

const ClientsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 12px 12px 12px;
`;

const SearchContainer = styled.div`
  width: 600px;
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 12px 0 12px;
`;

export const ClientColumns: ColumnProp[] = [
  {
    key: 'externalClientId',
    label: 'Company Number',
    sortField: 'externalClientId',
  },
  {
    key: 'totalInternalVendors',
    label: 'Total Mesa Suppliers',
    sortField: 'totalInternalVendors',
  },
  {
    key: 'totalDiverseInternalVendors',
    label: 'Total Diverse Mesa Suppliers',
    sortField: 'totalDiverseInternalVendors',
  },
  {
    key: 'totalServicedVendorsT12m',
    label: 'Total Mesa Funded Suppliers',
    sortField: 'totalServicedVendorsT12m',
    tooltip: 'Total number of suppliers enabled for Mesa with funded invoices in the past 12 months.',
  },
  {
    key: 'totalServicedInvoiceSpendT12m',
    label: 'Total Mesa Funded Spend',
    sortField: 'totalServicedInvoiceSpendT12m',
    type: 'currency',
    tooltip: 'Total funded invoice spend in the trailing 12 months.',
  },
];

const partnerId = 1; // TODO

function ClientsPage(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const searchTerm = useSelector((state) => state.clients.searchTerm);
  const page = useSelector((state) => state.clients.page) || 1;
  const totalPages = useSelector((state) => state.clients.totalPages);
  const limit = useSelector((state) => state.clients.limit) || DefaultPaginationDropdownLimits[0];
  const sortField = useSelector((state) => state.clients.sortField);
  const sortDirection = useSelector((state) => state.clients.sortDirection);

  const [searchInput, setSearchInput] = useState(searchTerm || '');

  const filter = useMemo(() => ({
    search: searchTerm || '',
    sortField,
    sortDirection,
    limit: (typeof limit === 'number' ? limit : limit.value),
    page,
  }), [searchTerm, sortField, sortDirection, limit, page]);

  const {
    data: {
      data: clients = [],
      total: totalClients,
    } = {},
    isFetching,
  } = useGetAllExternalClientsAsFacilitatorQuery({ accessToken: accessToken!, partnerId: partnerId!, ...filter }, { skip: !partnerId || !searchTerm });

  const rows = useMemo(() => clients.map((client) => ({
    id: `${client.partnerId}:${client.externalClientId}`,
    externalClientId: client.externalClientId,
    totalInternalVendors: client.totalInternalVendors,
    totalDiverseInternalVendors: client.totalDiverseInternalVendors,
    totalServicedVendorsT12m: client.totalServicedVendorsT12m,
    totalServicedInvoiceSpendT12m: client.totalServicedInvoiceSpendT12m,
    currency: 'USD',
  })), [clients]);

  useEffect(() => {
    if (totalClients !== undefined) {
      dispatch(updateTotalPages(Math.ceil(totalClients / (typeof limit === 'number' ? limit : limit.value))));
    }
  }, [limit, totalClients]);

  const onSubmitSearch = (value: string) => {
    const trimmed = value.trim();
    if (trimmed) {
      dispatch(updateSearchTerm(trimmed));
    } else {
      setSearchInput('');
      dispatch(updateSearchTerm(''));
    }
    dispatch(updatePage(1));
  };

  return (
    <ClientsPageContainer>
      <PageControlsContainer>
        <SearchContainer>
          <Search
            placeholder="Search by Company Number. Press Enter to search, Escape to clear"
            onChange={(value) => setSearchInput(value)}
            onSubmit={onSubmitSearch}
            value={searchInput}
            expanded
          />
        </SearchContainer>
      </PageControlsContainer>

      {!!searchTerm && (
        <Table
          isLoading={isFetching}
          columns={ClientColumns}
          rows={rows}
          currentSortColumn={sortField}
          currentSortDirection={sortDirection}
          setSortField={(field: string) => dispatch(updateSortField(field))}
          setSortDirection={(direction: api.SortDirection) => dispatch(updateSortDirection(direction))}
          onRowClick={(row: Record<string, unknown>) => navigate(`/clients/${row.externalClientId}`)}
          paginationComponent={(
            <PaginationContainer>
              <PaginationDropdown
                selectedLimit={(typeof limit === 'number' ? DefaultPaginationDropdownLimits.find((l) => l.value === limit) || DefaultPaginationDropdownLimits[0] : limit)}
                onChange={(item) => {
                  dispatch(updateLimit(item));
                  dispatch(updatePage(1));
                }}
              />

              <Pagination
                currentPage={page}
                totalPages={totalPages || 0}
                onPrev={() => dispatch(updatePage(page - 1))}
                onNext={() => dispatch(updatePage(page + 1))}
              />
            </PaginationContainer>
          )}
        />
      )}
    </ClientsPageContainer>
  );
}

export default ClientsPage;
