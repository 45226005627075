import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

const DefaultPaginationDropdownLimit = DefaultPaginationDropdownLimits[DefaultPaginationDropdownLimits.length - 1];

export type VendorsState = {
  searchTerm?: string;
  address?: string;
  serviceType?: string;
  locale?: string;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
  invoicesPage?: number;
  invoicesTotalPages?: number;
  invoicesLimit?: PaginationDropdownLimit;
  invoicesSortField?: string;
  invoicesSortDirection?: api.SortDirection;
};

const initialState: VendorsState = {
  searchTerm: '',
  address: undefined,
  serviceType: undefined,
  locale: undefined,
  page: 1,
  totalPages: undefined,
  limit: DefaultPaginationDropdownLimit,
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  invoicesPage: 1,
  invoicesTotalPages: undefined,
  invoicesLimit: DefaultPaginationDropdownLimit,
  invoicesSortField: undefined,
  invoicesSortDirection: api.SortDirection.ASCENDING,
};

const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    updateSearchTerm(state: VendorsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    updateAddress(state: VendorsState, action: PayloadAction<string>) {
      state.address = action.payload;
    },
    updateServiceType(state: VendorsState, action: PayloadAction<string>) {
      state.serviceType = action.payload;
    },
    updateLocale(state: VendorsState, action: PayloadAction<string>) {
      state.locale = action.payload;
    },
    updatePage(state: VendorsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: VendorsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: VendorsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: VendorsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: VendorsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateInvoicesPage(state: VendorsState, action: PayloadAction<number>) {
      state.invoicesPage = action.payload;
    },
    updateInvoicesTotalPages(state: VendorsState, action: PayloadAction<number>) {
      state.invoicesTotalPages = action.payload;
    },
    updateInvoicesLimit(state: VendorsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.invoicesLimit = action.payload;
    },
    updateInvoicesSortField(state: VendorsState, action: PayloadAction<string>) {
      state.invoicesSortField = action.payload;
    },
    updateInvoicesSortDirection(state: VendorsState, action: PayloadAction<api.SortDirection>) {
      state.invoicesSortDirection = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updateAddress,
  updateServiceType,
  updateLocale,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateInvoicesPage,
  updateInvoicesTotalPages,
  updateInvoicesLimit,
  updateInvoicesSortField,
  updateInvoicesSortDirection,
} = vendorsSlice.actions;
export default vendorsSlice.reducer;
