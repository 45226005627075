import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

const DefaultPaginationDropdownLimit = DefaultPaginationDropdownLimits[DefaultPaginationDropdownLimits.length - 1];

export type DivisionsState = {
  selectedDivision?: api.ExternalClientDivision;
  selectedTab?: number;
  clientsPage?: number;
  clientsTotalPages?: number;
  clientsLimit?: PaginationDropdownLimit;
  clientsSortField?: string;
  clientsSortDirection?: api.SortDirection;
  vendorsPage?: number;
  vendorsTotalPages?: number;
  vendorsLimit?: PaginationDropdownLimit;
  vendorsSortField?: string;
  vendorsSortDirection?: api.SortDirection;
};

export const initialState: DivisionsState = {
  selectedDivision: api.JllExternalClientDivision.Enterprise,
  selectedTab: 0,
  clientsPage: 1,
  clientsTotalPages: undefined,
  clientsLimit: DefaultPaginationDropdownLimit,
  clientsSortField: undefined,
  clientsSortDirection: api.SortDirection.ASCENDING,
  vendorsPage: 1,
  vendorsTotalPages: undefined,
  vendorsLimit: DefaultPaginationDropdownLimit,
  vendorsSortField: undefined,
  vendorsSortDirection: api.SortDirection.ASCENDING,
};

const divisionsSlice = createSlice({
  name: 'divisions',
  initialState,
  reducers: {
    updateSelectedDivision(state: DivisionsState, action: PayloadAction<api.ExternalClientDivision>) {
      state.selectedDivision = action.payload;
      state.clientsPage = 1;
      state.vendorsPage = 1;
    },
    updateSelectedTab(state: DivisionsState, action: PayloadAction<number>) {
      state.selectedTab = action.payload;
    },
    updateClientsPage(state: DivisionsState, action: PayloadAction<number>) {
      state.clientsPage = action.payload;
    },
    updateClientsTotalPages(state: DivisionsState, action: PayloadAction<number>) {
      state.clientsTotalPages = action.payload;
    },
    updateClientsLimit(state: DivisionsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.clientsLimit = action.payload;
    },
    updateClientsSortField(state: DivisionsState, action: PayloadAction<string>) {
      state.clientsSortField = action.payload;
    },
    updateClientsSortDirection(state: DivisionsState, action: PayloadAction<api.SortDirection>) {
      state.clientsSortDirection = action.payload;
    },
    updateVendorsPage(state: DivisionsState, action: PayloadAction<number>) {
      state.vendorsPage = action.payload;
    },
    updateVendorsTotalPages(state: DivisionsState, action: PayloadAction<number>) {
      state.vendorsTotalPages = action.payload;
    },
    updateVendorsLimit(state: DivisionsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.vendorsLimit = action.payload;
    },
    updateVendorsSortField(state: DivisionsState, action: PayloadAction<string>) {
      state.vendorsSortField = action.payload;
    },
    updateVendorsSortDirection(state: DivisionsState, action: PayloadAction<api.SortDirection>) {
      state.vendorsSortDirection = action.payload;
    },
  },
});

export const {
  updateSelectedDivision,
  updateSelectedTab,
  updateClientsPage,
  updateClientsTotalPages,
  updateClientsLimit,
  updateClientsSortField,
  updateClientsSortDirection,
  updateVendorsPage,
  updateVendorsTotalPages,
  updateVendorsLimit,
  updateVendorsSortField,
  updateVendorsSortDirection,
} = divisionsSlice.actions;
export default divisionsSlice.reducer;
