import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';

const DefaultPaginationDropdownLimit = DefaultPaginationDropdownLimits[DefaultPaginationDropdownLimits.length - 1];

export type ClientsState = {
  searchTerm?: string;
  page?: number;
  totalPages?: number;
  limit?: PaginationDropdownLimit;
  sortField?: string;
  sortDirection?: api.SortDirection;
  vendorsPage?: number;
  vendorsTotalPages?: number;
  vendorsLimit?: PaginationDropdownLimit;
  vendorsSortField?: string;
  vendorsSortDirection?: api.SortDirection;
};

const initialState: ClientsState = {
  searchTerm: '',
  page: 1,
  totalPages: undefined,
  limit: DefaultPaginationDropdownLimit,
  sortField: undefined,
  sortDirection: api.SortDirection.ASCENDING,
  vendorsPage: 1,
  vendorsTotalPages: undefined,
  vendorsLimit: DefaultPaginationDropdownLimit,
  vendorsSortField: undefined,
  vendorsSortDirection: api.SortDirection.ASCENDING,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    updateSearchTerm(state: ClientsState, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    updatePage(state: ClientsState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateTotalPages(state: ClientsState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    updateLimit(state: ClientsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.limit = action.payload;
    },
    updateSortField(state: ClientsState, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },
    updateSortDirection(state: ClientsState, action: PayloadAction<api.SortDirection>) {
      state.sortDirection = action.payload;
    },
    updateVendorsPage(state: ClientsState, action: PayloadAction<number>) {
      state.vendorsPage = action.payload;
    },
    updateVendorsTotalPages(state: ClientsState, action: PayloadAction<number>) {
      state.vendorsTotalPages = action.payload;
    },
    updateVendorsLimit(state: ClientsState, action: PayloadAction<PaginationDropdownLimit>) {
      state.vendorsLimit = action.payload;
    },
    updateVendorsSortField(state: ClientsState, action: PayloadAction<string>) {
      state.vendorsSortField = action.payload;
    },
    updateVendorsSortDirection(state: ClientsState, action: PayloadAction<api.SortDirection>) {
      state.vendorsSortDirection = action.payload;
    },
  },
});

export const {
  updateSearchTerm,
  updatePage,
  updateTotalPages,
  updateLimit,
  updateSortField,
  updateSortDirection,
  updateVendorsPage,
  updateVendorsTotalPages,
  updateVendorsLimit,
  updateVendorsSortField,
  updateVendorsSortDirection,
} = clientsSlice.actions;
export default clientsSlice.reducer;
