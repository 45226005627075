import { types as api } from '@mesa-labs/mesa-api';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  CardContainer,
  DownloadButton,
  Dropdown,
  LoadingScreen,
  PaginationFooter,
  Table,
} from '@mesa-labs/mesa-ui';
import { DefaultPaginationDropdownLimits, PaginationDropdownLimit } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import { DropdownOption } from '@mesa-labs/mesa-ui/dist/components/Dropdown';
import ResourceCard, { ResourceItemProp } from '@mesa-labs/mesa-ui/dist/components/ResourceCard';

import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';
import {
  asExportFilter, exportAllExternalClientsAsFacilitator, exportAllExternalVendorsAsFacilitator, useGetAllExternalClientsAsFacilitatorQuery, useGetAllExternalVendorsAsFacilitatorQuery, useGetExternalClientDivisionAsFacilitatorQuery,
} from '../../redux/api/facilitators';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateSelectedDivision, initialState as InitialDivisionsState,
  updateVendorsLimit, updateVendorsPage, updateVendorsTotalPages, updateVendorsSortField, updateVendorsSortDirection,
  updateClientsLimit, updateClientsPage, updateClientsTotalPages, updateClientsSortField, updateClientsSortDirection, updateSelectedTab,
} from '../../redux/slices/divisions';

const DivisionsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 12px 12px 12px;
`;

const SearchContainer = styled.div`
  width: 600px;
`;

const TabContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 0 12px 12px 12px;
`;

const DownloadContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const DivisionItems: (string | ResourceItemProp)[] = [
  { key: 'totalClients', label: 'Total Clients', width: '100%' },
  { key: 'totalInternalVendors', label: 'Total Mesa Suppliers', tooltip: 'Total number of suppliers enabled for Mesa.' },
  { key: 'totalDiverseInternalVendors', label: 'Total Diverse Mesa Suppliers', tooltip: 'Total number of diverse suppliers enabled for Mesa.' },
  { key: 'totalServicedVendorsT12m', label: 'Total Mesa Funded Suppliers', tooltip: 'Total number of suppliers enabled for Mesa with funded invoices in the past 12 months.' },
  {
    key: 'totalServicedInvoiceSpendT12m', label: 'Total Mesa Funded Spend', tooltip: 'Total funded invoice spend in the trailing 12 months.', type: 'currency',
  },
];

export const ClientColumns: ColumnProp[] = [
  {
    key: 'externalClientId',
    label: 'Company Number',
    sortField: 'externalClientId',
  },
  {
    key: 'totalInternalVendors',
    label: 'Total Mesa Suppliers',
    sortField: 'totalInternalVendors',
  },
  {
    key: 'totalDiverseInternalVendors',
    label: 'Total Diverse Mesa Suppliers',
    sortField: 'totalDiverseInternalVendors',
  },
  {
    key: 'totalServicedVendorsT12m',
    label: 'Total Mesa Funded Suppliers',
    sortField: 'totalServicedVendorsT12m',
    tooltip: 'Total number of suppliers enabled for Mesa with funded invoices in the past 12 months.',
  },
  {
    key: 'totalServicedInvoiceSpendT12m',
    label: 'Total Mesa Funded Spend',
    sortField: 'totalServicedInvoiceSpendT12m',
    type: 'currency',
    tooltip: 'Total funded invoice spend in the trailing 12 months.',
  },
];

export const VendorColumns: ColumnProp[] = [
  {
    key: 'externalVendorName',
    label: 'Name',
    sortField: 'externalVendorName',
  },
  {
    key: 'externalVendorId',
    label: 'Supplier Id(s)',
    sortField: 'externalVendorId',
  },
  {
    key: 'activatedAt',
    label: 'Enablement Date',
    sortField: 'activatedAt',
    type: 'date',
    tooltip: 'An empty value indicates that the Supplier has signed up for Mesa but has not yet been enabled within JLL',
  },
  {
    key: 'diverse',
    label: 'Diverse?',
    sortField: 'diverse',
  },
];

const partnerId = 1; // TODO

const ExternalClientDivisionDropdownOptions: DropdownOption<api.ExternalClientDivision>[] = [
  ...Object.values(api.JllExternalClientDivision).map((v: api.JllExternalClientDivision) => ({
    label: v,
    value: v,
  })),
];

function DivisionsPage(): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const selectedDivision = useSelector((state) => state.divisions.selectedDivision) || InitialDivisionsState.selectedDivision!;
  const selectedTab = useSelector((state) => state.divisions.selectedTab) || InitialDivisionsState.selectedTab!;
  const clientsPage = useSelector((state) => state.divisions.clientsPage) || 1;
  const clientsTotalPages = useSelector((state) => state.divisions.clientsTotalPages);
  const clientsLimit = useSelector((state) => state.divisions.clientsLimit) || InitialDivisionsState.clientsLimit!;
  const clientsSortField = useSelector((state) => state.divisions.clientsSortField);
  const clientsSortDirection = useSelector((state) => state.divisions.clientsSortDirection);
  const vendorsPage = useSelector((state) => state.divisions.vendorsPage) || 1;
  const vendorsTotalPages = useSelector((state) => state.divisions.vendorsTotalPages);
  const vendorsLimit = useSelector((state) => state.divisions.vendorsLimit) || InitialDivisionsState.vendorsLimit!;
  const vendorsSortField = useSelector((state) => state.divisions.vendorsSortField);
  const vendorsSortDirection = useSelector((state) => state.divisions.vendorsSortDirection);

  const {
    data: division,
    isFetching: isDivisionFetching,
  } = useGetExternalClientDivisionAsFacilitatorQuery({ accessToken: accessToken!, partnerId: partnerId!, division: selectedDivision }, { skip: !partnerId });

  const clientsFilter = useMemo(() => ({
    division: selectedDivision,
    sortField: clientsSortField,
    sortDirection: clientsSortDirection,
    limit: (typeof clientsLimit === 'number' ? clientsLimit : clientsLimit.value),
    page: clientsPage,
  }), [selectedDivision, clientsSortField, clientsSortDirection, clientsLimit, clientsPage]);

  const {
    data: {
      data: clients = [],
      total: totalClients,
    } = {},
    isFetching: isClientsFetching,
  } = useGetAllExternalClientsAsFacilitatorQuery({ accessToken: accessToken!, partnerId: partnerId!, ...clientsFilter }, { skip: !partnerId });

  const clientRows = useMemo(() => clients.map((client) => ({
    id: `${client.partnerId}:${client.externalClientId}`,
    externalClientId: client.externalClientId,
    totalInternalVendors: client.totalInternalVendors,
    totalDiverseInternalVendors: client.totalDiverseInternalVendors,
    totalServicedVendorsT12m: client.totalServicedVendorsT12m,
    totalServicedInvoiceSpendT12m: client.totalServicedInvoiceSpendT12m,
    currency: 'USD',
  })), [clients]);

  useEffect(() => {
    if (totalClients !== undefined) {
      dispatch(updateClientsTotalPages(Math.ceil(totalClients / (typeof clientsLimit === 'number' ? clientsLimit : clientsLimit.value))));
    }
  }, [clientsLimit, totalClients]);

  const vendorsFilter = useMemo(() => ({
    clientDivision: selectedDivision,
    sortField: vendorsSortField,
    sortDirection: vendorsSortDirection,
    limit: (typeof vendorsLimit === 'number' ? vendorsLimit : vendorsLimit.value),
    page: vendorsPage,
  }), [selectedDivision, vendorsSortField, vendorsSortDirection, vendorsLimit, vendorsPage]);

  const {
    data: {
      data: vendors = [],
      total: totalVendors,
    } = {},
    isFetching: isVendorsFetching,
  } = useGetAllExternalVendorsAsFacilitatorQuery({ accessToken: accessToken!, partnerId: partnerId!, ...vendorsFilter }, { skip: !partnerId });

  const vendorRows = useMemo(() => vendors.map((vendor) => ({
    id: vendor.internalVendorId,
    externalVendorName: vendor.externalVendorName,
    externalVendorId: vendor.externalVendorId,
    activatedAt: vendor.activatedAt,
    diverse: vendor.diverse,
  })), [vendors]);

  useEffect(() => {
    if (totalVendors !== undefined) {
      dispatch(updateVendorsTotalPages(Math.ceil(totalVendors / (typeof vendorsLimit === 'number' ? vendorsLimit : vendorsLimit.value))));
    }
  }, [vendorsLimit, totalVendors]);

  return (
    <DivisionsPageContainer>
      <PageControlsContainer>
        <SearchContainer>
          <Dropdown
            options={ExternalClientDivisionDropdownOptions}
            selectedOption={selectedDivision}
            onChange={(sd) => dispatch(updateSelectedDivision(sd))}
            width="100%"
          />
        </SearchContainer>
      </PageControlsContainer>

      <CardContainer>
        {!!division && (
          <ResourceCard
            resource={{
              ...division,
              currency: 'USD',
            }}
            title=""
            items={DivisionItems}
            columns={4}
          />
        )}
        {!division && isDivisionFetching && (
          <LoadingScreen />
        )}
      </CardContainer>

      <TabContainer>
        <Button text="Clients" color={selectedTab === 0 ? 'primary' : 'secondary'} onClick={() => dispatch(updateSelectedTab(0))} />
        <Button text="Suppliers" color={selectedTab === 1 ? 'primary' : 'secondary'} onClick={() => dispatch(updateSelectedTab(1))} />
        <DownloadContainer>
          <DownloadButton
            text="Download CSV"
            data={() => (selectedTab === 0
              ? exportAllExternalClientsAsFacilitator(partnerId, asExportFilter(clientsFilter), accessToken!)
              : exportAllExternalVendorsAsFacilitator(partnerId, asExportFilter(vendorsFilter), accessToken!))}
            fileName={
              selectedTab === 0
                ? 'clients.csv'
                : 'suppliers.csv'
            }
          />
        </DownloadContainer>
      </TabContainer>

      {selectedTab === 0 && (
        <Table
          isLoading={isClientsFetching}
          columns={ClientColumns}
          rows={clientRows}
          currentSortColumn={clientsSortField}
          currentSortDirection={clientsSortDirection}
          setSortField={(field: string) => dispatch(updateClientsSortField(field))}
          setSortDirection={(direction: api.SortDirection) => dispatch(updateClientsSortDirection(direction))}
          onRowClick={(row: Record<string, unknown>) => navigate(`/clients/${row.externalClientId}`)}
          paginationComponent={(
            <PaginationFooter
              direction="up"
              limits={DefaultPaginationDropdownLimits as PaginationDropdownLimit[]}
              selectedLimit={(typeof clientsLimit === 'number' ? DefaultPaginationDropdownLimits.find((l) => l.value === clientsLimit) || DefaultPaginationDropdownLimits[0] : clientsLimit)}
              onChange={(item) => {
                dispatch(updateClientsLimit(item));
                dispatch(updateClientsPage(1));
              }}
              currentPage={clientsPage}
              totalPages={clientsTotalPages || 0}
              onPrev={() => {
                dispatch(updateClientsPage(clientsPage - 1));
              }}
              onNext={() => {
                dispatch(updateClientsPage(clientsPage + 1));
              }}
            />
          )}
        />
      )}

      {selectedTab === 1 && (
        <Table
          isLoading={isVendorsFetching}
          columns={VendorColumns}
          rows={vendorRows}
          currentSortColumn={vendorsSortField}
          currentSortDirection={vendorsSortDirection}
          setSortField={(field: string) => dispatch(updateVendorsSortField(field))}
          setSortDirection={(direction: api.SortDirection) => dispatch(updateVendorsSortDirection(direction))}
          onRowClick={(row: Record<string, unknown>) => navigate(`/suppliers/${row.id}`)}
          paginationComponent={(
            <PaginationFooter
              direction="up"
              limits={DefaultPaginationDropdownLimits as PaginationDropdownLimit[]}
              selectedLimit={(typeof vendorsLimit === 'number' ? DefaultPaginationDropdownLimits.find((l) => l.value === vendorsLimit) || DefaultPaginationDropdownLimits[0] : vendorsLimit)}
              onChange={(item) => {
                dispatch(updateVendorsLimit(item));
                dispatch(updateVendorsPage(1));
              }}
              currentPage={vendorsPage}
              totalPages={vendorsTotalPages || 0}
              onPrev={() => {
                dispatch(updateVendorsPage(clientsPage - 1));
              }}
              onNext={() => {
                dispatch(updateVendorsPage(clientsPage + 1));
              }}
            />
          )}
        />
      )}
    </DivisionsPageContainer>
  );
}

export default DivisionsPage;
