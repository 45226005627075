import { combineReducers } from 'redux';

import auth from './auth';
import vendors from './vendors';
import clients from './clients';
import divisions from './divisions';
import { facilitatorsApi } from '../api/facilitators';

const reducer = combineReducers({
  auth,
  vendors,
  clients,
  divisions,
  [facilitatorsApi.reducerPath]: facilitatorsApi.reducer,
});

export default reducer;
