import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
  clientId: CONFIG.okta.clientId,
  issuer: CONFIG.okta.issuer,
  redirectUri: CONFIG.okta.redirectUri,
  postLogoutRedirectUri: CONFIG.okta.postLogoutRedirectUri,
  scopes: CONFIG.okta.scopes,
  pkce: CONFIG.okta.pkce,
});

export default oktaAuth;
