import { Typography } from '@mesa-labs/mesa-ui';
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  width: 100%;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-around;
  padding-top: 24px;
`;

const FooterText = styled(Typography.BodyMedium)`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.Black50};
`;

export const FooterPadding = '36px';

function Footer(): React.ReactElement {
  return (
    <FooterContainer>
      <FooterText>
        Contact
        {' '}
        <a href="mailto:jll@joinmesa.com">jll@joinmesa.com</a>
        {' '}
        for questions or support.
      </FooterText>
    </FooterContainer>
  );
}

export default Footer;
