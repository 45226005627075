import {
  SortDirection,
} from '@mesa-labs/mesa-api/dist/types';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CardContainer,
  DownloadButton,
  LoadingScreen,
  Pagination,
  PaginationDropdown,
  ResourceSection,
  Table,
  Typography,
} from '@mesa-labs/mesa-ui';
import ResourceCard, { ResourceItemProp } from '@mesa-labs/mesa-ui/dist/components/ResourceCard';

import { ColumnProp } from '@mesa-labs/mesa-ui/dist/components/Table';
import { DefaultPaginationDropdownLimits } from '@mesa-labs/mesa-ui/dist/components/Pagination';
import {
  asExportFilter,
  exportAllExternalClientVendorsAsFacilitator,
  useGetExternalClientAsFacilitatorQuery,
  useGetExternalClientVendorsAsFacilitatorQuery,
} from '../../redux/api/facilitators';
import { useDispatch, useSelector } from '../../redux/hooks';
import {
  updateVendorsLimit, updateVendorsPage, updateVendorsSortDirection, updateVendorsSortField, updateVendorsTotalPages,
} from '../../redux/slices/clients';

const ClientPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const VendorDetailsPageControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px 12px 0 12px;
`;

const ClientItems: (string | ResourceItemProp)[] = [
  { key: 'externalClientId', label: 'Company Number', width: '100%' },
  { key: 'totalInternalVendors', label: 'Total Mesa Suppliers', tooltip: 'Total number of suppliers enabled for Mesa.' },
  { key: 'totalDiverseInternalVendors', label: 'Total Diverse Mesa Suppliers', tooltip: 'Total number of diverse suppliers enabled for Mesa.' },
  { key: 'totalServicedVendorsT12m', label: 'Total Mesa Funded Suppliers', tooltip: 'Total number of suppliers enabled for Mesa with funded invoices in the past 12 months.' },
  {
    key: 'totalServicedInvoiceSpendT12m', label: 'Total Mesa Funded Spend', tooltip: 'Total funded invoice spend in the trailing 12 months.', type: 'currency',
  },
];

const VendorColumns: ColumnProp[] = [
  {
    key: 'externalVendorName',
    label: 'Name',
    sortField: 'externalVendorName',
  },
  {
    key: 'externalVendorId',
    label: 'Supplier Id(s)',
    sortField: 'externalVendorId',
  },
  {
    key: 'internalActivatedAt',
    label: 'Enablement Date',
    sortField: 'internalActivatedAt',
    type: 'date',
  },
  {
    key: 'diverse',
    label: 'Diverse?',
    sortField: 'diverse',
  },
  {
    key: 'servicedInvoiceSpendT12m',
    label: 'Client Mesa Funded Spend',
    sortField: 'servicedInvoiceSpendT12m',
    type: 'currency',
  },
];

const partnerId = 1; // TODO

function ClientPage(): React.ReactElement | null {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: externalClientId } = useParams();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const vendorsSortField = useSelector((state) => state.clients.vendorsSortField);
  const vendorsSortDirection = useSelector((state) => state.clients.vendorsSortDirection);
  const vendorsPage = useSelector((state) => state.clients.vendorsPage) || 1;
  const vendorsTotalPages = useSelector((state) => state.clients.vendorsTotalPages);
  const vendorsLimit = useSelector((state) => state.clients.vendorsLimit) || DefaultPaginationDropdownLimits[0];

  const {
    data: client,
  } = useGetExternalClientAsFacilitatorQuery({
    accessToken: accessToken!,
    partnerId: partnerId!,
    externalClientId: externalClientId!,
  }, { skip: !partnerId || !externalClientId });

  const vendorFilter = useMemo(() => ({
    sortField: vendorsSortField,
    sortDirection: vendorsSortDirection,
    page: vendorsPage,
    limit: (typeof vendorsLimit === 'number' ? vendorsLimit : vendorsLimit.value),
  }), [vendorsSortField, vendorsSortDirection, vendorsPage, vendorsLimit]);

  const {
    data: {
      data: vendors,
      total: totalVendors,
    } = {},
  } = useGetExternalClientVendorsAsFacilitatorQuery({
    accessToken: accessToken!,
    partnerId: partnerId!,
    externalClientId: externalClientId!,
    ...vendorFilter,
  }, { skip: !partnerId || !externalClientId });

  useEffect(() => {
    if (totalVendors !== undefined) {
      dispatch(updateVendorsTotalPages(Math.ceil(totalVendors / (typeof vendorsLimit === 'number' ? vendorsLimit : vendorsLimit.value))));
    }
  }, [totalVendors, vendorsLimit]);

  const vendorRows = useMemo(() => (vendors || []).map((vendor) => ({
    id: `${vendor.partnerId}:${vendor.externalVendorId}`,
    internalVendorId: vendor.internalVendorId,
    externalVendorName: vendor.externalVendorName,
    externalVendorId: vendor.externalVendorId,
    internalActivatedAt: vendor.internalActivatedAt,
    diverse: vendor.diverse,
    servicedInvoiceSpendT12m: vendor.servicedInvoiceSpendT12m,
    currency: 'USD',
  })), [vendors]);

  return (
    <ClientPageContainer>
      <CardContainer>
        {client !== undefined && (
          <ResourceCard
            resource={{
              ...client,
              currency: 'USD',
            }}
            title=""
            items={ClientItems}
            columns={4}
          />
        )}
        {client === undefined && (
          <LoadingScreen />
        )}
      </CardContainer>

      <ResourceSection
        title="Enabled Suppliers"
        controls={(
          <VendorDetailsPageControlsContainer>
            <DownloadButton
              text="Download CSV"
              data={() => exportAllExternalClientVendorsAsFacilitator(partnerId, externalClientId!, asExportFilter(vendorFilter), accessToken!)}
              fileName="client_suppliers.csv"
            />
          </VendorDetailsPageControlsContainer>
        )}
      >
        <>
          {vendors !== undefined && (
            <Table
              columns={VendorColumns}
              rows={vendorRows}
              currentSortColumn={vendorsSortField}
              currentSortDirection={vendorsSortDirection}
              setSortField={(field: string) => dispatch(updateVendorsSortField(field))}
              setSortDirection={(direction: SortDirection) => dispatch(updateVendorsSortDirection(direction))}
              onRowClick={(row: Record<string, unknown>) => navigate(`/suppliers/${row.externalVendorId}`)}
            />
          )}
          {vendors === undefined && (
            <LoadingScreen />
          )}

          {!!vendorsTotalPages && (
            <PaginationContainer>
              <PaginationDropdown
                selectedLimit={(typeof vendorsLimit === 'number' ? DefaultPaginationDropdownLimits.find((l) => l.value === vendorsLimit) || DefaultPaginationDropdownLimits[0] : vendorsLimit)}
                onChange={(item) => {
                  dispatch(updateVendorsLimit(item));
                  dispatch(updateVendorsPage(1));
                }}
              />
              <Typography.BodySmallBold>
                Total of
                {' '}
                {totalVendors !== undefined ? totalVendors : '...'}
                {' '}
                Supplier(s)
              </Typography.BodySmallBold>
              <Pagination
                currentPage={vendorsPage}
                totalPages={vendorsTotalPages}
                onPrev={() => {
                  dispatch(updateVendorsPage(vendorsPage - 1));
                }}
                onNext={() => {
                  dispatch(updateVendorsPage(vendorsPage + 1));
                }}
              />
            </PaginationContainer>
          )}
        </>
      </ResourceSection>
    </ClientPageContainer>
  );
}

export default ClientPage;
