import { OktaAuth } from '@okta/okta-auth-js';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { types as api } from '@mesa-labs/mesa-api';

interface AuthState {
  isAuthorized: boolean | undefined;
  email: string | undefined;
  accessToken: string | undefined;
  accessTokenExpiresAt: number | undefined;
}

const initialState: AuthState = {
  isAuthorized: undefined,
  email: undefined,
  accessToken: undefined,
  accessTokenExpiresAt: undefined,
};

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (oktaAuth: OktaAuth): Promise<void> => {
    await oktaAuth.signOut();
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAccessToken(state: AuthState, action: PayloadAction<{ email: string, tokenContainer: api.TokenContainer } | undefined>) {
      state.isAuthorized = !!action.payload;
      state.email = action.payload?.email;
      state.accessToken = action.payload?.tokenContainer.token;
      state.accessTokenExpiresAt = action.payload?.tokenContainer.expiresAt;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signOut.fulfilled, (state: AuthState, _action: PayloadAction<void>) => {
        state.isAuthorized = false;
        state.email = undefined;
        state.accessToken = undefined;
        state.accessTokenExpiresAt = undefined;
      })
      .addCase(signOut.rejected, (state: AuthState, _error: any) => {
        state.isAuthorized = false;
        state.email = undefined;
        state.accessToken = undefined;
        state.accessTokenExpiresAt = undefined;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {
  updateAccessToken,
} = authSlice.actions;

export default authSlice.reducer;
