import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from '@mesa-labs/mesa-ui';

export const FilterSectionContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 0 12px 24px 12px;
  z-index: 29;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FilterHeader = styled.div`
  color: ${(props) => props.theme.colors.Black90};
  font-size: 20px;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
`;

const AnimationContainer = styled(motion.div)`
  padding-top: 16px;
`;

export const FilterTitle = styled.div`
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2px; 
`;

export const FilterRowContainer = styled.div<{ columnCount: number; }>`
  align-items: flex-start;
  display: grid;
  gap: 8px 12px;
  grid-auto-flow: column;
  margin-bottom: 16px;
  grid-template-columns: ${(props) => `repeat(${props.columnCount}, 1fr);`}

  &:first-of-type {
    margin-top: 8px;
  }
`;

export const FilterCell = styled.div`
  display: flex;
  flex-direction: column;
`;

type FilterSectionProps = {
  children?: ReactNode
  filterHeader?: string;
  filterControl?: ReactNode;
  isInitiallyOpen?: boolean;
  appliedFilters?: number;
};

export function FilterSection({
  children,
  filterHeader,
  filterControl,
  isInitiallyOpen,
  appliedFilters,
}: FilterSectionProps) {
  const [open, setOpen] = useState(isInitiallyOpen || false);

  return (
    <FilterSectionContainer>
      <HeaderContainer>
        <>
          {filterHeader && <FilterHeader>{filterHeader}</FilterHeader>}
          {filterControl && (
            filterControl
          )}

          {children && (
            <ButtonContainer>
              <Button
                text={
                  open
                    ? `Hide Filters${appliedFilters ? ` (${appliedFilters})` : ''}`
                    : `Show Filters${appliedFilters ? ` (${appliedFilters})` : ''}`
                }
                iconSrc="/assets/filter-icon.svg"
                onClick={() => setOpen(!open)}
              />
            </ButtonContainer>
          )}
        </>
      </HeaderContainer>

      <AnimatePresence>
        {open && (
          <AnimationContainer
            variants={{
              hidden: { opacity: 0 },
              show: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.5,
                  type: 'spring',
                },
              },
            }}
            animate="show"
            initial="hidden"
            exit="hidden"
          >
            {children}
          </AnimationContainer>
        )}
      </AnimatePresence>
    </FilterSectionContainer>
  );
}

FilterSection.defaultProps = {
  children: undefined,
  filterHeader: undefined,
  filterControl: undefined,
  isInitiallyOpen: false,
  appliedFilters: undefined,
};

type FilterRowProps = {
  children: ReactNode | readonly ReactNode[];
};

export function FilterRow({ children }: FilterRowProps) {
  return (
    <FilterRowContainer columnCount={Array.isArray(children) ? children.length : 1}>
      {children}
    </FilterRowContainer>
  );
}
