import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  FormContainer,
  Typography,
} from '@mesa-labs/mesa-ui';
import { useOktaAuth } from '@okta/okta-react';

export const randomString = (length = 10): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  return Array.from(new Array(length), () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
};

const SignInContainer = styled(FormContainer)`
  background: transparent;
  padding: 32px;
  margin: 0px auto;
  max-width: 600px;
`;

const Header = styled(Typography.H2)`
  color: ${(props) => props.theme.colors.Midnight};
  margin-bottom: 4px;
`;

const SubHeader = styled(Typography.BodySmall)`
  color: ${(props) => props.theme.colors.Black50};
  margin-bottom: 32px;
`;

const ButtonContainer = styled.div`
  & > div {
    width: 100%;
  }
`;

function SignInPage(): ReactElement {
  const { oktaAuth } = useOktaAuth();

  const handleSignIn = async () => {
    const nonce = randomString(25);
    await oktaAuth.signInWithRedirect({ originalUri: `/authorize?nonce=${nonce}`, nonce });
  };

  return (
    <SignInContainer>
      <Card>
        <Header>Sign in with Okta</Header>
        <SubHeader>
          Mesa partners with JLL to provide authentication to this app. Please
          sign in by clicking on the button below, which will forward you to
          Okta and redirect you back once sign in is complete.
        </SubHeader>

        <ButtonContainer>
          <Button onClick={handleSignIn} text="Sign in" width="100%" />
        </ButtonContainer>
      </Card>
    </SignInContainer>
  );
}

export default SignInPage;
