import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { LoadingScreen } from '@mesa-labs/mesa-ui';

import AuthorizePage from '../components/pages/Authorize';
import SignInPage from '../components/pages/SignIn';
import VendorsPage from '../components/pages/Vendors';
import VendorPage from '../components/pages/Vendor';
import ClientsPage from '../components/pages/Clients';
import ClientPage from '../components/pages/Client';
import DivisionsPage from '../components/pages/Divisions';
import OktaSecureRoute from './OktaSecureRoute';
import { useSelector } from '../redux/hooks';

function AppRoutes(): ReactElement {
  const isAuthorized = useSelector((state) => state.auth.isAuthorized);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/suppliers" replace />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/oauth" element={<LoginCallback loadingElement={<LoadingScreen />} />} />
      <Route path="/authorize" element={isAuthorized ? <Navigate to="/suppliers" /> : <AuthorizePage />} />
      <Route path="/suppliers" element={<OktaSecureRoute />}>
        <Route path="" element={<VendorsPage />} />
        <Route path=":id" element={<VendorPage />} />
      </Route>
      <Route path="/clients" element={<OktaSecureRoute />}>
        <Route path="" element={<ClientsPage />} />
        <Route path=":id" element={<ClientPage />} />
      </Route>
      <Route path="/divisions" element={<OktaSecureRoute />}>
        <Route path="" element={<DivisionsPage />} />
        {/* <Route path=":id" element={<ClientPage />} /> */}
      </Route>
    </Routes>
  );
}

export default AppRoutes;
