import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import {
  Card,
  FormContainer,
  LoadingScreen,
  Typography,
} from '@mesa-labs/mesa-ui';
import { types as api } from '@mesa-labs/mesa-api';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';
import { ThunkActionDispatch } from 'redux-thunk';

import { updateAccessToken } from '../../redux/slices/auth';
import { useDispatch } from '../../redux/hooks';
import { useAuthorizeFacilitatorQuery } from '../../redux/api/facilitators';

const AuthorizeContainer = styled(FormContainer)`
  background: #FDFDFD;
  padding: 32px;
  margin: 0px auto;
  max-width: 600px;
`;

const Header = styled(Typography.H2)`
  color: ${(props) => props.theme.colors.Midnight};
  margin-bottom: 4px;
`;

const SubHeader = styled(Typography.BodyMedium)`
  color: ${(props) => props.theme.colors.Black50};
`;

const partnerId = api.Partners.JLL; // TODO

function AuthorizePage(): ReactElement {
  const dispatch = useDispatch() as ThunkActionDispatch<any>;
  const { oktaAuth } = useOktaAuth();
  const location = useLocation();

  const [token, setToken] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [isForbidden, setIsForbidden] = useState(false);

  useEffect(() => {
    setToken(oktaAuth.getIdToken());
    oktaAuth.getUser().then((user) => setEmail(user.email));
  }, []);

  const nonce = useMemo(() => {
    if ((location?.state as any)?.nonce) {
      return (location.state as any).nonce as string | undefined;
    }

    const params = new URLSearchParams(location.search);
    if (params.get('nonce')) {
      return params.get('nonce') || undefined;
    }

    return undefined;
  }, [location.state, location.search]);

  const {
    data: tokenContainer,
    isFetching,
    isError,
  } = useAuthorizeFacilitatorQuery({
    token: token || '',
    nonce: nonce || '',
    partnerId,
  }, { skip: !token || !nonce });

  useEffect(() => {
    if (email && tokenContainer) {
      dispatch(updateAccessToken({ email, tokenContainer }));
      // dispatch(authorize({ oktaAuth, nonce }));
    } else if (isError) {
      dispatch(updateAccessToken(undefined));
      setIsForbidden(true);
    }
  }, [email, tokenContainer, isError]);

  return (
    <AuthorizeContainer>
      <>
        {isFetching && (
          <LoadingScreen />
        )}
        {isForbidden && (
          <Card>
            <Header>Authorization Pending</Header>
            <SubHeader>
              {email ? `${email} has` : 'You have'}
              {' '}
              been submitted for access approval.
              <br />
              <br />
              Once approved, you will receive an email from Mesa within one business day. If you have any questions or your access isn&apos;t granted in a timely manner, please reach out to
              {' '}
              <a href="mailto:jll@joinmesa.com">jll@joinmesa.com</a>
              .
            </SubHeader>
          </Card>
        )}
      </>
    </AuthorizeContainer>
  );
}

export default AuthorizePage;
